<template>
  <v-container>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('metaReport.metaAccounting.margins.table.title1') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="marginsByProvider" group-by="name" :loading="loading" hide-default-footer fixed-header>
            <template #[`group.header`]="{items, isOpen, toggle}">
              <th>
                <v-icon @click="toggle">
                  {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class="text-uppercase">{{ items[0].name }}</span>
              </th>
              <th />
              <th />
              <th />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <cycle-selector :default="cycle" @update="updateCycle" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('metaReport.metaAccounting.margins.table.title2') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="marginsByLocation" group-by="name" :loading="loading" hide-default-footer fixed-header>
            <template #[`group.header`]="{items, isOpen, toggle}">
              <th>
                <v-icon @click="toggle">
                  {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class="text-uppercase">{{ items[0].name }}</span>
              </th>
              <th />
              <th />
              <th />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <cycle-selector :default="cycle" @update="updateCycle" />
  </v-container>
</template>

<script>
  import { getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import CycleSelector from '@/views/components/common/CycleSelector';

  export default {
    name: 'MetaMarginsAccounting',
    components: { CycleSelector, PeriodSelector },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        headers: [
          {
            text: this.$i18n.t('metaReport.metaAccounting.margins.table.name'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'name'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.margins.table.date'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'date'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.margins.table.revenue'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'revenue'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.margins.table.saleFee'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'saleFee'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.margins.table.purchaseCost'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'purchaseCost'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['date'],
          sortDesc: [true],
          groupBy: ['name'],
          groupDesc: [false],
          multiSort: false,
          mustSort: false
        },
        marginsByLocation: [],
        marginsByProvider: [],
        cycle: 'monthly'
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'accounting/meta/margins?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate) + '&cycle=' + this.cycle, { timeout: 60000 });
          if (response.data !== undefined) {
            this.marginsByLocation = response.data.locations;
            this.marginsByProvider = response.data.providers;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
        this.loading = false;
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      },
      async updateCycle (cycle) {
        this.cycle = cycle;
        await this.refreshData();
      }
    }
  };
</script>
