<template>
  <v-container id="dashboard" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('metaReport.metaAccounting.revenues.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaAccounting.margins.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <div class="text-h3 text-center">
              Ces chiffres ne sont pas fiables. Le TTC est une estimation, la TVA et le HT sont faux.
            </div>
            <meta-revenues-accounting :refresh="refreshRevenues" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-margins-accounting :refresh="refreshMargins" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import MetaMarginsAccounting from '@/views/components/business/accounting/meta/MetaMarginsAccounting';
  import MetaRevenuesAccounting from '@/views/components/business/accounting/meta/MetaRevenuesAccounting';

  export default {
    name: 'MetaAccounting',
    components: { AlertNotification, MetaMarginsAccounting, MetaRevenuesAccounting },
    data () {
      return {
        alert: '',
        currentTab: 0,
        loading: false,
        refreshRevenues: null,
        refreshMargins: null
      };
    },
    mounted () {},
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        switch (tab) {
          case 0:
            this.refreshRevenues = Date.now();
            break;
          case 1:
            this.refreshMargins = Date.now();
            break;
        }
        this.loading = true;
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
