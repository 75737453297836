<template>
  <v-row dense>
    <v-col cols="12" class="text-center">
      <v-btn-toggle v-model="cycle" borderless dark>
        <v-btn value="daily">
          {{ $t('common.cycle.daily') }}
        </v-btn>
        <v-btn value="weekly">
          {{ $t('common.cycle.weekly') }}
        </v-btn>
        <v-btn value="monthly">
          {{ $t('common.cycle.monthly') }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'CycleSelector',
    props: {
      default: {
        type: String,
        default: 'daily'
      }
    },
    data () {
      return {
        cycle: 'daily'
      };
    },
    watch: {
      cycle: async function () {
        this.update();
      }
    },
    mounted: async function () {
      this.cycle = this.default;
      this.update();
    },
    methods: {
      update () {
        this.$emit('update', this.cycle);
      }
    }
  };
</script>
