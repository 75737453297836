var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('period-selector',{attrs:{"start-date-prop":_vm.startDate,"start-hour-prop":_vm.startHour,"end-date-prop":_vm.endDate,"end-hour-prop":_vm.endHour,"loading":_vm.loading},on:{"update":_vm.updatePeriod}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('metaReport.metaAccounting.margins.table.title1'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.marginsByProvider,"group-by":"name","loading":_vm.loading,"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(items[0].name))])],1),_c('th'),_c('th'),_c('th')]}}],null,true)})],1)],1)],1),_c('cycle-selector',{attrs:{"default":_vm.cycle},on:{"update":_vm.updateCycle}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('metaReport.metaAccounting.margins.table.title2'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.marginsByLocation,"group-by":"name","loading":_vm.loading,"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(items[0].name))])],1),_c('th'),_c('th'),_c('th')]}}],null,true)})],1)],1)],1),_c('cycle-selector',{attrs:{"default":_vm.cycle},on:{"update":_vm.updateCycle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }