<template>
  <v-container>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('metaReport.metaAccounting.revenues.table.title') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="days" :loading="loading">
            <template #[`body.prepend`]>
              <tr>
                <th class="text-center d-block d-sm-table-cell">
                  {{ $t('metaReport.metaAccounting.revenues.table.total') }}
                </th>
                <th class="text-center d-block d-sm-table-cell" />
                <th class="text-center d-block d-sm-table-cell">
                  {{ total.count }}
                </th>
                <th class="text-center d-block d-sm-table-cell">
                  {{ total.avgTaxIncluded }}
                </th>
                <th class="text-center d-block d-sm-table-cell">
                  {{ total.totalTaxExcluded }}
                </th>
                <th class="text-center d-block d-sm-table-cell" style="height: 100%">
                  {{ total.totalTaxes }}<br>
                  <span v-html="formatTaxes(total)" />
                </th>
                <th class="text-center d-block d-sm-table-cell">
                  {{ total.totalTaxIncluded }}
                </th>
              </tr>
            </template>
            <template #[`item.totalTaxes`]="{ item }">
              {{ item.totalTaxes }}
            </template>
            <template #[`item.date`]="{ item }">
              {{ formatDate(item.date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayDateISO, displayWeekday, getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'MetaRevenuesAccounting',
    components: { PeriodSelector },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        loading: false,
        headers: [
          {
            text: this.$i18n.t('metaReport.metaAccounting.revenues.table.date'),
            align: 'start',
            filterable: false,
            sortable: true,
            value: 'date'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.revenues.table.day'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'day'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.revenues.table.count'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'count'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.revenues.table.avgTaxIncluded'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'avgTaxIncluded'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.revenues.table.totalTaxExcluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'totalTaxExcluded'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.revenues.table.totalTaxes'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'totalTaxes'
          },
          {
            text: this.$i18n.t('metaReport.metaAccounting.revenues.table.totalTaxIncluded'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'totalTaxIncluded'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['date'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        days: [],
        total: {}
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      formatDate: displayDateISO,
      formatTaxes (item) {
        return (item.taxes === undefined ? '' : Object.keys(item.taxes).sort().map((taxRate) => {
          return this.$i18n.t('metaReport.metaAccounting.revenues.table.tax') + ' ' + item.taxes[taxRate].taxRate + ' : ' + item.taxes[taxRate].amount;
        }).join('<br>'));
      },
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const fromDate = encodeURIComponent(this.startFullDate);
          const toDate = encodeURIComponent(this.endFullDate);
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'accounting/meta/revenues/daily?from=' + fromDate + '&to=' + toDate, { timeout: 60000 });
          if (response.data !== undefined && response.data.days !== undefined) {
            this.days = response.data.days;
            this.days.forEach((item) => {
              item.day = displayWeekday(item.date);
            });
            this.total = response.data.total;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
        this.loading = false;
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
